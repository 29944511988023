/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "These are the guides that will help you to understand and navigate the UI of Signatu:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "trackerdetectuiguide"
  }, "Trackerdetect UI Guide")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "dataprocessinguiguide"
  }, "Data Processing UI Guide")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "policyuiguide"
  }, "Policy UI Guide")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
